import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const app1 = {
    apiKey: "AIzaSyADVOmX4B453X6q_RLPfo6JbsND_rrkgIE",
    authDomain: "pcr-test-portal.firebaseapp.com",
    databaseURL: "https://pcr-test-portal.firebaseio.com",
    projectId: "pcr-test-portal",
    storageBucket: "pcr-test-portal.appspot.com",
    messagingSenderId: "47948833742",
    appId: "1:47948833742:web:ff556334274ec897cd819f",
    measurementId: "G-8H00C5P3XQ"
};
  
var app = firebase.initializeApp(app1);
/*const storage = firebase.storage;

export  {
   storage, app as default
}
 */
export default app;
