import React, { Component } from 'react';
//import { HashRouter, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './scss/style.scss';
import { AuthProvider } from "./context/Auth";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/auth/login/Login'));
const VerifyOTP = React.lazy(() => import('./views/auth/verifyOTP/VerifyOTP'));
const Register = React.lazy(() => import('./views/auth/register/Register'));
const CheckResults = React.lazy(() => import('./views/unauth/CheckResults'));
const VenueCheckIn = React.lazy(() => import('./views/unauth/VenueCheckIn'));
const ForgotPassword = React.lazy(() => import('./views/auth/forgotPassword/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/auth/page404/Page404'));
const Page500 = React.lazy(() => import('./views/auth/page500/Page500'));

class App extends Component {

  render() {
    return (
      <AuthProvider>
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/verify-otp" name="OTP Page" render={props => <VerifyOTP {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />     
              <Route exact path="/forgot-password" name="Forgot Password Page" render={props => <ForgotPassword {...props} />} />  
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/check-results" name="Register Page" render={props => <CheckResults {...props} />} />
              <Route exact path="/check-in" name="Register Page" render={props => <VenueCheckIn {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              
              <Route exact path="/change-password" name="Change Password" render={props => <TheLayout {...props} />} />
              
              <Route exact path="/dashboard" name="Home" render={props => <TheLayout {...props} />} />

              <Route exact path="/p-passenger-select-test-type" name="Home" render={props => <TheLayout {...props} />} />

              

              <Route exact path="/p-passenger-data" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-passenger-select-lab" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-passenger-payment-process" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-passenger-view-all-requests" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-passenger-request-details" name="Home" render={props => <TheLayout {...props} />} />

              <Route exact path="/p-up-passenger-data" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-up-passenger-select-lab" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-up-passenger-select-datetime" name="Home" render={props => <TheLayout {...props} />} />


              <Route exact path="/p-physician-view-all-requests" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-physician-view-my-requests" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-physician-request-details" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/p-physician-download-csv" name="Home" render={props => <TheLayout {...props} />} />
              


              <Route exact path="/l-lab-complete-requests" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/l-lab-open-requests" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/l-lab-test-detail" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/l-lab-upload-csv" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/l-lab-qr-scan" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/l-lab-location-details" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/l-lab-download-csv" name="Home" render={props => <TheLayout {...props} />} />


              
              
              <Route exact path="/a-admin-all-employer" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-add-employer" name="Home" render={props => <TheLayout {...props} />} />

              <Route exact path="/a-admin-labs" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-lab-user-add" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-physician" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-physician-add" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-lab-create" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-lab-details" name="Home" render={props => <TheLayout {...props} />} />

              


              <Route exact path="/a-admin-custom-labs" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-request-list" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-request-detail" name="Home" render={props => <TheLayout {...props} />} />
              
              <Route exact path="/a-admin-unauth-results-complete" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-unauth-results-pending" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-unauth-results-add" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-just-report-all" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-just-report" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-just-report-csv" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-unauth-results-update" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-unauth-csv" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-synergy-csv" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-view-users" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-admin-user-details" name="Home" render={props => <TheLayout {...props} />} />
              
              <Route exact path="/a-venue-qr" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-venue-pending" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-venue-complete" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/a-venue-details" name="Home" render={props => <TheLayout {...props} />} />

              <Route exact path="/e-all-tests" name="Home" render={props => <TheLayout {...props} />} />



              <Route exact path="*" name="Page 404" render={props => <Page404 {...props}/>} />
            </Switch>
            </React.Suspense>
          </Router>
    </AuthProvider>
    );
  }
}

export default App;


/*
 <Route path="/dashboard" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/business-info" name="Business Info" render={props => <TheLayout {...props} />} />
              <Route path="/business-addresses" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/add-roles" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/employees" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/manage-schedule" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/shift-trades" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/time-off-vacations" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/clock-in-out" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/shift-feedback" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/view-announcements" name="Users" render={props => <TheLayout {...props} />} />
              <Route path="/open-roles" name="Users" render={props => <TheLayout {...props} />} />
              <Route path="/messages" name="Users" render={props => <TheLayout {...props} />} />
*/